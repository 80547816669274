
* {
    scrollbar-color: transparent;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
      background:transparent;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    box-sizing: border-box;
}
h1,h2,h3,h4,h5,h6 { font-size:100%}
/* Removes list-style from lists */
ol,ul { list-style:none }
/* Normalizes font-size and font-weight to normal */
address,caption,cite,code,dfn,em,strong,th,var { font-size:normal; font-weight:normal }

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
a{text-decoration: none;}


//
html {
  body {
      font-size: 12px;
      line-height: 1.5;
      font-family: 'Paddle', PingFangSC-Regular, 'Microsoft YaHei', sans-serif;
      letter-spacing: 0.5px;
      height: 100%;
      width: 100vw;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-tap-highlight-color: transparent;
      // transition: background-color 0.3s ease-out;
      // &::-webkit-scrollbar {
      //     display: none;
      // }
      #root{
        display: flex;
        flex-direction: column;
        min-height: 100%;

      }
      .page{
        height: 100vh;
      }

      #lo_loading{width:0%; height:0.2rem; position:fixed; top:0; left:0; z-index: 999999;}
      #lo_loading.active{width:100%; transition: width 0.8s;}
  }
}




// icon style
.icontoken {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  line-height: 1 !important;
}