
.headerModule{
    position: absolute;
    width: 100%;
    height: 120px;
    backdrop-filter: blur(10px);
    z-index: 10;
    padding: 0 48px;
}


@media screen and (max-width: 768px) {
    .headerModule{
        position: absolute;
        width: 100%;
        height: 80px;
        backdrop-filter: blur(10px);
        z-index: 10;
        padding: 0 24px;
    }
}