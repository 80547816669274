// General style specification
@media screen and (min-width:1200px) {
    .l-show {
        display: auto;
    }
    .l-hide {
        display: none !important;
    }
}
@media (min-width:768px) and (max-width:1199px) {
    .m-show {
        display: auto;
    }
    .m-hide {
        display: none !important;
    }
}
@media screen and (max-width: 767px) {
    .s-show {
        display: auto;
    }
    .s-hide {
        display: none !important;
    }
}



// Font-size [++2]
@for $i from 5 through 32 {
    .fs_#{$i * 2} {font-size: $i * 2px !important;}
}

// Icon-size [++4]
@for $i from 3 through 16 {
    .is_#{$i * 4} {font-size: $i * 4px;}
}

// Font weight
@for $i from 4 through 7 {
    .fw_#{$i}00 {font-weight: $i * 100;}
}

// Opacity 0-1
@for $i from 0 through 10 {
    .opacity_#{$i}{opacity: $i / 10;}
}

// w100,h50 ,,,,
@for $i from 0 through 50{
    .w#{$i * 2} {width: $i * 2%;}
    .h#{$i * 2} {height: $i * 2%;}
    .mh#{$i * 2} {min-height: $i * 2%;}
    .wx#{$i * 2} {max-width: $i * 2%;}
}

// border radius [++4]
@for $i from 0 through 12 {
    .br_#{$i * 4} { border-radius: $i * 4px !important; }
    .br_t_#{$i * 4} { border-radius: $i * 4px $i * 4px 0 0 !important; }
    .br_b_#{$i * 4} { border-radius: 0 0 $i * 4px $i * 4px !important; }
}
.br_100{ border-radius: 100vh; }
.br_t_100{ border-radius: 100vh 100vh 0 0 !important; }


// padding,margin
$spacing: 2,4,8,12,16,20,24,32,40,48,56,64,96,128;

@each $count in $spacing {

    .pa_#{$count} {
        padding: #{$count}px !important;
    }
    .pl_#{$count} {
        padding-left: #{$count}px !important;
    }
    .pr_#{$count} {
        padding-right: #{$count}px !important;
    }
    .pt_#{$count} {
        padding-top: #{$count}px !important;
    }
    .pb_#{$count} {
        padding-bottom: #{$count}px !important;
    }
    .px_#{$count} {
        padding-left: #{$count}px !important;
        padding-right: #{$count}px !important;
    }
    .py_#{$count} {
        padding-top: #{$count}px !important;
        padding-bottom: #{$count}px !important;
    }

    .ml_#{$count} {
        margin-left: #{$count}px !important;
    }
    .mr_#{$count} {
        margin-right: #{$count}px !important;
    }
    .mt_#{$count} {
        margin-top: #{$count}px !important;
    }
    .mb_#{$count} {
        margin-bottom: #{$count}px !important;
    }
    .my_#{$count} {
        margin-top: #{$count}px !important;
        margin-bottom: #{$count}px !important;
    }
}
.m_a_x {
    margin: 0 auto !important;
}


// gap_
@each $count in $spacing {
    .gap_#{$count} { gap: #{$count}px; }
}

// flex : 1;
@for $i from 1 through 10 {
    .flex_#{$i}{ flex: $i; }
}


.grid{
    display:grid;
}
.grid_rows {
    grid-template-rows: max-content(10);
}
.grid_column {
    grid-template-columns: repeat;
}

// flex : 1;
@for $i from 2 through 10 {
    .g_r_#{$i}{ grid-template-columns: repeat($i,1fr); }
}
.g_r_3_a{ grid-template-columns: 40% 30% auto; }
.g_r_3_b{ grid-template-columns: 40% 35% auto; }
.g_r_3_c{grid-template-columns: 50% 20% auto;}
.g_r_4_a{ grid-template-columns: repeat(3,26%) auto; }
.g_r_5_a{ grid-template-columns: repeat(3,24%) 14% auto; }
.g_r_6_a{ grid-template-columns: repeat(4,19%) 12% 12%; }
.g_r_7_a{ grid-template-columns: repeat(6,15%) 10%; }



//  ------  end-------------

.flex{
    display: flex;
}
.flex_shrink{
    flex-shrink: 0;
}
.flex_wrap{
    flex-wrap: wrap;
}
.align_center{
    align-items: center;
}
.align_start{
    align-items: flex-start;
}
.align_end{
    align-items: flex-end;
}
.column{
    flex-direction: column;
}

.justify_center{
justify-content: center;
}
.justify_between{
justify-content: space-between;
}
.justify_around{
justify-content: space-around;
}
.justify_end {
justify-content: flex-end;
}



.rotate_x_90 {
transform: rotateX(90deg);
display: inline-block
}

.rotate_x_180 {
transform: rotateX(180deg);
display: inline-block
}

.rotate_x_270 {
transform: rotateX(270deg);
display: inline-block
}
.rotate_y_90 {
    transform: rotateY(90deg);
    display: inline-block
}
.rotate_y_180 {
    transform: rotateY(180deg);
    display: inline-block
}
.rotate_y_270 {
    transform: rotateY(270deg);
    display: inline-block
}




.text_center{
text-align: center;
}
.text_left{
    text-align: left;
}
.text_right{
    text-align: right;
}


.p_relative{
    position: relative;
}
.p_absolute{
    position: absolute;
    top: 0;
}
.p_top{
    top:0;
}
.p_bottom{
    bottom: 110%;
    top: auto;
}
.p_right{
    right:0;
}
.p_fixed{
    position: fixed;
}
.p_sticky{
    position: sticky;
}
.p_inset{
    inset: 0;
}
.zindex_low{
    z-index: -1;
}
.zindex_0{
    z-index: 0;
}
.zindex_1{
    z-index: 0;
}

.cursor{
    cursor: pointer;
}
.cursor_not{
    cursor:not-allowed !important;
}

.inline_block{
    display: inline-block !important;
}
.block{
    display: block;
}
.visibility{
    visibility: hidden;
}
.display_none{
    display: none !important;
}





.scroll_y {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
// .scroll_y::-webkit-scrollbar {
// display: none;
// }
.scroll_y_w {
    scrollbar-width: auto !important;
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        background:transparent;
        }
}
.scroll_x {
scrollbar-width: none;
-ms-overflow-style: none;
overflow-x: scroll;
-webkit-overflow-scrolling: touch;
}
// .scroll_x::-webkit-scrollbar {
//   display: none;
// }


.overflow_hidden {
overflow: hidden;
}

.overflow_auto {
    overflow: auto;
    }

.white_space_nowrap {
white-space: nowrap;
}


.break_word {
word-wrap: break-word;
}


.break_all {
word-break: break-all;
}


.t_es {
text-overflow: ellipsis;
overflow: hidden;
}

.t_es_2{
text-overflow: -o-ellipsis-lastline;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
line-clamp: 2;
-webkit-box-orient: vertical;
}

.t_es_1{
text-overflow: -o-ellipsis-lastline;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
line-clamp: 1;
-webkit-box-orient: vertical;
}


.border_box{box-sizing: border-box;}
.scale_5{transform: scale(0.5);}
.no_select{
    user-select: none;
}

.text_capitalize {
    text-transform: capitalize;
}
.text_upper {
    text-transform: uppercase;
}

// line-height
.lh_12{line-height: 12px !important;}
.lh_14{line-height: 14px !important;}
.lh_16{line-height: 16px !important;}
.lh_1x{line-height: 1 !important;}
.lh_2x{line-height: 2 !important;}


