// 两种模式颜色不一样 使用 light/drak
// 两种模式颜色一样 使用 theme

// main color
$dark_main_1: #FF9900;
$dark_main_2: #f7f5ff;


// theme color
$theme_up: #03A66D;
$theme_down: #f6465d;

$theme_auxiliary_1: #06D9E8; // 
$theme_auxiliary_2: #FF289C; // red
$theme_auxiliary_3: #F23D31; // blue
$theme_auxiliary_4: #FFAF51; // yellow
$theme_auxiliary_5: #1890FF; // yellow
$theme_auxiliary_6: #ffffff;  // white
$theme_auxiliary_7: #000000;  // black

// background color
$dark_background_2: #35353A;    // level 1
$dark_background_3: #262629;    // level 2
$dark_background_4: #2F2F33;    // level 3
$dark_background_5: #2B2B2E;  // level 2 fill

// font color
$dark_font_2: rgba($dark_main_2,0.9); // level 1 : title
$dark_font_3: rgba($dark_main_2,0.5); // level 2 : detail
$dark_font_4: rgba($dark_main_2,0.3); // level 3 : placeholder

// border color
$dark_border_2: rgba($dark_main_2, 0.04); // division
$dark_border_3: rgba($dark_main_2, 0.12); // input

// shadow
$dark_box_shadow_1: rgba($theme_auxiliary_7, .1);
$dark_box_shadow_2: rgba($theme_auxiliary_7, .2);
$dark_box_shadow_3: rgba($theme_auxiliary_7, 0.4); // card shadow
$dark_box_shadow_4: rgba($theme_auxiliary_7, 0.4); // super


// gradient
$light_linear_gradient_1: 116.06deg, #ff9900 16.73%, #07a1f8 49.61%;




.page{
    color: $dark_main_2;
    background-color:$dark_background_2;
}


a{ color: $dark_font_2; }

    
// Theme Colors
.text_1{
    color: $dark_main_1 !important;
}
.text_2{
    color: $dark_font_2 !important;
}
.text_3{
    color: $dark_font_3;
}
.text_4{
    color: $dark_font_4;
}
.text_binance{
    color:#FCD535;
}
.text_black{
    color: $theme_auxiliary_7 !important;
}
.text_white{
    color: rgba($theme_auxiliary_6,0.9) !important;
}
.text_up{
    color: $theme_up;
}
.text_down{
    color: $theme_down;
}

// Card color
.b_g_1{
    background-color:$dark_main_1 !important;
}
.b_g_1_1{
    background-color:rgba($dark_main_1, 0.1) !important;
}
.b_g_1_6{
    background-color:rgba($dark_main_1, 0.6) !important;
}
.b_g_2{
    background-color: $dark_background_2 !important;
}
.b_g_3{
    background-color: $dark_background_3 !important;
}
.b_g_4{
    background-color: $dark_background_4 !important;
}
.b_g_5{
    background-color: $dark_background_5 !important;
}


.shadow_1{
    box-shadow: 0px 4px 26px 0px $dark_box_shadow_1;
}
.shadow_2{
    box-shadow: 0px 4px 8px $dark_box_shadow_2;
}
.shadow_3{
    box-shadow: 0 4px 48px $dark_box_shadow_3;
}

.linear_bg_1{
    background: linear-gradient(120deg, #7863FB 16%, #5350FD 50%, #FB3CFF 84%);
}
.linear_c_1{
    background-image:-webkit-linear-gradient(120deg, #7863FB 16%, #5350FD 50%, #FB3CFF 84%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
}


// frame
.bd{
    border: 1px solid $dark_border_2;
}
.bd_t{
    border-top: 1px solid $dark_border_2;
}
.bd_b{
    border-bottom: 1px solid $dark_border_2;
}
.bd_l{
    border-left: 1px solid $dark_border_2;
}
.bd_r{
    border-right: 1px solid $dark_border_2;
}

.bd_c_0{
    border-color: transparent;
}
.bd_c_1{
    border-color: $dark_main_1;
}
.bd_c_2{
    border-color: $dark_border_2;
}
.bd_c_3{
    border-color: $dark_border_3;
}
.bd_s_d{
    border-style: dashed;
}
.bd_w_2{
    border-width: 2px !important;
}
.bd_w_3{
    border-width: 3px !important;
}
.bd_w_4{
    border-width: 4px !important;
}

$auxiliaryArr: ($theme_auxiliary_1 $theme_auxiliary_2 $theme_auxiliary_3 $theme_auxiliary_4 $theme_auxiliary_5 $theme_auxiliary_6 $theme_auxiliary_7);
@for $i from 1 through 7 {
    .a_c_#{$i}{ color: nth($auxiliaryArr, $i); }
    .a_c_#{$i}_1{ color: rgba(nth($auxiliaryArr, $i),0.1); }
    .a_c_#{$i}_2{ color: rgba(nth($auxiliaryArr, $i),0.2); }
    .a_c_#{$i}_4{ color: rgba(nth($auxiliaryArr, $i),0.4); }
    .a_c_#{$i}_6{ color: rgba(nth($auxiliaryArr, $i),0.6); }

    .abg_#{$i}{ background-color: nth($auxiliaryArr, $i); }
    .abg_#{$i}_1{ background-color: rgba(nth($auxiliaryArr, $i),0.1); }
    .abg_#{$i}_2{ background-color: rgba(nth($auxiliaryArr, $i),0.2); }
    .abg_#{$i}_4{ background-color: rgba(nth($auxiliaryArr, $i),0.4); }
    .abg_#{$i}_6{ background-color: rgba(nth($auxiliaryArr, $i),0.6); }

    .bd_a_c_#{$i}{ border-color: nth($auxiliaryArr, $i); }
    .bd_a_c_#{$i}_1{ border-color: rgba(nth($auxiliaryArr, $i),0.1); }
    .bd_a_c_#{$i}_2{ border-color: rgba(nth($auxiliaryArr, $i),0.2); }
    .bd_a_c_#{$i}_4{ border-color: rgba(nth($auxiliaryArr, $i),0.4); }
    .bd_a_c_#{$i}_6{ border-color: rgba(nth($auxiliaryArr, $i),0.6); }
}


// ------------------------------  end ------------------------
// mask
.mask_1{
    background-color: rgba($theme_auxiliary_7,0.3);
}


// :hover
.hover{
    &:hover.hover_show{ display: inherit; }
}
.hover_c_1{
    transition: all 0.2s;
    &:hover{
        color: $dark_main_1 !important;
    }
}
.hover_c_2{
    transition: all 0.2s;
    &:hover{
        color: $dark_font_2 !important;
    }
}

.hover_bd_1{
    transition: all 0.2s;
    &:hover{
        border-width: 1px;
        border-style: solid;
        border-color: $dark_main_1 !important;
    }
}

.hover_bg_1{
    transition: all 0.2s;
    &:hover{
        padding: 2px 12px;
        border-radius: 12px;
        background-color: rgba($dark_main_1,0.1) !important;
    }
}
.hover_bg_2{
    transition: all 0.2s;
    &:hover{
        padding: 0 0 0 12px;
        border-radius: 12px;
        background-color: rgba($dark_main_2,0.1) !important;
    }
}
.hover_line{
    transition: all 0.2s;
    &:hover{
        text-decoration: underline
    }
}